import React, { Fragment, useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import { Oval } from 'react-loader-spinner';
import get from 'lodash.get';
import './App.css';

function App() {
  let [url, setUrl] = useState('');
  let [rubybluPixelFound, setRubybluPixelFound] = useState(false);
  let [retargetingPixelFound, setRetargetingPixelFound] = useState(false);
  let [audiohookPixelFound, setAudiohookPixelFound] = useState(false);
  let [beaconFiring, setBeaconFiring] = useState(false);
  // let [privacyStringFound, setPrivacyStringFound] = useState(false);
  let [checking, setChecking] = useState(false);
  let [checked, setChecked] = useState(false);
  let [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    const regexpString = /^https?:\/\//;

    if (checking) {
      fetch('https://pixel-check-api.rubyblu.com/check-pixel', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          url: regexpString.test(url) ? url : `https://${url}`,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          setRubybluPixelFound(get(json, 'rubyblu_pixel.is_present', false));
          setRetargetingPixelFound(
            get(json, 'gam_retargeting_pixel.is_present', false)
          );
          setAudiohookPixelFound(
            get(json, 'audiohook_pixel.is_present', false)
          );
          setBeaconFiring(get(json, 'beacon.is_posting', false));
          // setPrivacyStringFound(get(json, 'beacon.privacy_string', false));
          setChecking(false);
          setChecked(true);
        })
        .catch((e) => {
          setChecking(false);
          setErrorOccurred(true);
          console.log('ERROR', e);
        });
    }
  }, [checking, url]);

  const checkForBeacon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setChecking(true);
    setErrorOccurred(false);
  };

  const handleURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.currentTarget.value);
  };

  const getIcon = (value: boolean) => {
    if (checking) {
      return <Oval height={30} width={30} />;
    }
    if (errorOccurred) {
      return <ErrorIcon fontSize="large" color="error" />;
    }
    if (checked) {
      if (value) {
        return <CheckBoxIcon fontSize="large" color="success" />;
      }
      return <DisabledByDefaultIcon fontSize="large" color="error" />;
    }
    return <CheckBoxOutlineBlankIcon color="disabled" fontSize="large" />;
  };

  return (
    <Fragment>
      <div className="urlInput">
        <TextField
          id="input"
          type={'string'}
          variant="outlined"
          label="URL"
          className="URL"
          onChange={handleURLChange}
        />
        <Button variant="contained" onClick={checkForBeacon}>
          Check
        </Button>
      </div>
      <div className="hint">
        Enter full URL, ex. <i>https://www.ksl.com</i> (handy hint: copy
        directly from client website)
      </div>
      <div className="status-outer">
        <div className="status-inner">
          <div className="left items">
            <div className="item">
              {getIcon(retargetingPixelFound)}
              <Typography className="name">
                Onsite Retargeting Pixel Found
              </Typography>
            </div>
            <div className="item">
              {getIcon(rubybluPixelFound)}
              <Typography className="name">Offsite Pixel Found</Typography>
            </div>
            <div className="item">
              {getIcon(audiohookPixelFound)}
              <Typography className="name">Audio Pixel Found</Typography>
            </div>
          </div>
          <div className="right items">
            <div className="item">
              {getIcon(beaconFiring)}
              <Typography className="name">User Beacons Firing</Typography>
            </div>
          </div>
        </div>
      </div>
      {errorOccurred ? (
        <div className="error">
          An error occurred, please try again. If problem persists, please reach
          out to the PinPoint Dev Team.
        </div>
      ) : null}
      {checking ? (
        <div className="patience">
          Please be patient. Checking can take up to two minutes.
        </div>
      ) : null}
    </Fragment>
  );
}

export default App;
